@import-normalize;

* { margin: 0; padding: 0; color:#333333; line-height: 1.5; }
body {
  background-color: #FFFBF3;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
               sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media all and (width > 480px) {
  body { margin: 60px 100px; font-size: 90%; }
}
@media all and (width <= 480px) {
  body { margin: 30px 30px 40px; font-size: 90%; }
}

.loading span {
  display: inline-block;
  margin: 0 0.05rem;
  animation: loading .6s infinite;
}

.loading span:nth-child(1),
.loading span:nth-child(7),
.loading span:nth-child(13) { animation-delay: .0s; color: #FFB300; }

.loading span:nth-child(2),
.loading span:nth-child(8) { animation-delay: .1s; color: #7CB342; }

.loading span:nth-child(3),
.loading span:nth-child(9) { animation-delay: .2s; color: #1E88E5; }

.loading span:nth-child(4),
.loading span:nth-child(10) { animation-delay: .3s; color: #FDD835; }

.loading span:nth-child(5),
.loading span:nth-child(11) { animation-delay: .4s; color: #C0CA33; }

.loading span:nth-child(6),
.loading span:nth-child(12) { animation-delay: .5s; color: #03A9F4; }

@keyframes loading {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(0.9); }
}

h2 { padding-top: 40px; }
